























































































.search-input {
  height: 40px;
}

.checkbox-product {
  .el-checkbox {
    display: block;
  }
}
